.bg-ethereum {
  @apply bg-zinc-400
  dark:bg-zinc-500;
}

.bg-ethereum-40 {
  @apply bg-zinc-400/40
  dark:bg-zinc-600/40;
}

.border-ethereum {
  @apply border-zinc-400
  dark:border-zinc-600;
}

.bg-optimism {
  @apply bg-red-400
  dark:bg-red-600;
}

.bg-optimism-40 {
  @apply bg-red-400/40
  dark:bg-red-600/40;
}

.border-optimism {
  @apply border-red-400
  dark:border-red-600;
}

.bg-gnosis {
  @apply bg-teal-400
  dark:bg-teal-600;
}

.bg-gnosis-40 {
  @apply bg-teal-400/40
  dark:bg-teal-600/40;
}

.border-gnosis {
  @apply border-teal-400
  dark:border-teal-600;
}

.bg-polygon {
  @apply bg-violet-400
  dark:bg-violet-600;
}

.bg-polygon-40 {
  @apply bg-violet-400/40
  dark:bg-violet-600/40;
}

.border-polygon {
  @apply border-violet-400
  dark:border-violet-600;
}

.bg-fantom {
  @apply bg-indigo-400
  dark:bg-indigo-600;
}

.bg-fantom-40 {
  @apply bg-indigo-400/40
  dark:bg-indigo-600/40;
}

.border-fantom {
  @apply border-indigo-400
  dark:border-indigo-600;
}

.bg-sonic {
  @apply bg-amber-400
  dark:bg-neutral-600;
}

.bg-sonic-40 {
  @apply bg-amber-400/40
  dark:bg-neutral-600/40;
}

.border-sonic {
  @apply border-amber-400
  dark:border-neutral-600;
}

.bg-base {
  @apply bg-blue-400
  dark:bg-blue-500;
}

.bg-base-40 {
  @apply bg-blue-400/40
  dark:bg-blue-600/40;
}

.border-base {
  @apply border-blue-400
  dark:border-blue-600;
}

.bg-mode {
  @apply bg-indigo-400
  dark:bg-indigo-600;
}

.bg-mode-40 {
  @apply bg-indigo-400/40
  dark:bg-indigo-600/40;
}

.border-mode {
  @apply border-indigo-400
  dark:border-indigo-600;
}

.bg-arbitrum {
  @apply bg-[#28a1ef]
  dark:bg-[#28a1ef];
}

.bg-arbitrum-40 {
  @apply bg-[#28a1ef]/40
  dark:bg-[#28a1ef]/40;
}

.border-arbitrum {
  @apply border-[#28a1ef]
  dark:border-[#28a1ef];
}






.bg-berachain {
  @apply bg-[#a15e28]
  dark:bg-[#a15e28];
}

.bg-berachain-40 {
  @apply bg-[#a15e28]/40
  dark:bg-[#a15e28]/40;
}

.border-berachain {
  @apply border-[#a15e28]
  dark:border-[#a15e28];
}