@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:ital,wght@0,300;0,800;1,300&display=swap');

html {
  @apply w-full max-h-screen overflow-hidden;
}

body {
  @apply bg-secondary-900 select-none;
}

.rainbow-text {
  @apply bg-clip-text text-transparent 
  bg-gradient-to-r 
  from-purple-500 to-pink-500;
}

.override-scroll {
  @apply sm:scrollbar-thin 
  sm:scrollbar-track-secondary-50 sm:scrollbar-thumb-secondary-300 sm:hover:scrollbar-thumb-selected-400
  dark:sm:scrollbar-track-primary-950 dark:sm:scrollbar-thumb-primary-900 dark:sm:hover:scrollbar-thumb-selected-500;
}

.a {
  @apply underline underline-offset-2 hover:text-selected-400 active:text-selected-600;
}

.attention-text {
  @apply text-attention-600 dark:text-attention-400 group-hover:text-current group-active:text-current;
}

.attention-box {
  @apply border border-attention-600 dark:border-attention-400 text-attention-600 dark:text-attention-400 bg-attention-100 dark:bg-attention-950;
}